import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import React from "react";
import Navbar from './components/NavBar';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/Home';

import SignIn from './pages/login/Sign-in';
import SignUp from './pages/login/Sign-up';
import EmailResetPass from './pages/login/EmailResetPass';
import ResetPass from './pages/login/ResetPass';
import { AuthProvider } from './pages/login/OAuth';

import ListClients from './pages/clients/ListClients';
import NewClient from './pages/clients/NewClient';
import EditClient from './pages/clients/EditClient';
import ListClientInvoices from './pages/clients/ListClientInvoices';
import ListClientRetentions from './pages/clients/ListClientRetentions';

import ListProviders from './pages/providers/ListProviders';
import NewProvider from './pages/providers/NewProvider';
import EditProvider from './pages/providers/EditProvider';
import ListProviderInvoices from './pages/providers/ListProviderInvoices';
import ListProviderRetentions from './pages/providers/ListProviderRetentions';

import NewOffer from './pages/offers/NewOffer';
import EditOffer from './pages/offers/EditOffer';
import ListOffers from './pages/offers/ListOffers';

import NewOrder from './pages/orders/NewOrder';
import ListOrders from './pages/orders/ListOrders';
import EditOrder from './pages/orders/EditOrder';

import NewBuilding from './pages/buildings/NewBuilding';
import ListBuildings from './pages/buildings/ListBuildings';
import EditBuilding from './pages/buildings/EditBuilding';

import NewCertifications from './pages/certifications/NewCertifications';
import ListCertifications from './pages/certifications/ListCertifications';
import EditCertifications from './pages/certifications/EditCertifications';

import NewExpense from './pages/expenses/NewExpense';
import ListExpenses from './pages/expenses/ListExpenses';
import EditExpense from './pages/expenses/EditExpense';

import UserEdit from './pages/users/UserEdit';
import Error404 from './pages/errors/Error404';

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Navbar />
          <Box sx={{ marginTop: 10, marginLeft: 10, marginBottom: 10, marginRight: 5 }}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/sign-in' element={<SignIn />} />
              <Route path='/sign-up' element={<SignUp />} />
              <Route path='/reset-pass' element={<EmailResetPass />} />
              <Route path='/reset-pass/:token' element={<ResetPass />} />

              <Route element={<ProtectedRoute />}>
                <Route path='/clients/list' element={<ListClients />} />
                <Route path='/clients/new' element={<NewClient />} />
                <Route path='/clients/update/:id' element={<EditClient />} />
                <Route path='/clients/invoices' element={<ListClientInvoices />} />
                <Route path='/clients/retentions' element={<ListClientRetentions />} />

                <Route path='/providers/list' element={<ListProviders />} />
                <Route path='/providers/new' element={<NewProvider />} />
                <Route path='/providers/update/:id' element={<EditProvider />} />
                <Route path='/providers/invoices' element={<ListProviderInvoices />} />
                <Route path='/providers/retentions' element={<ListProviderRetentions />} />

                <Route path='/offers/new' element={<NewOffer />} />
                <Route path='/offers/update/:id' element={<EditOffer />} />
                <Route path='/offers/list' element={<ListOffers />} />

                <Route path='/orders/new' element={<NewOrder />} />
                <Route path='/orders/list' element={<ListOrders />} />
                <Route path='/orders/update/:id' element={<EditOrder />} />

                <Route path='/buildings/new' element={<NewBuilding />} />
                <Route path='/buildings/list' element={<ListBuildings />} />
                <Route path='/buildings/update/:id' element={<EditBuilding />} />

                <Route path='/certifications/new' element={<NewCertifications />} />
                <Route path='/certifications/list' element={<ListCertifications />} />
                <Route path='/certifications/update/:id' element={<EditCertifications />} />

                <Route path='/expenses/new' element={<NewExpense />} />
                <Route path='/expenses/list' element={<ListExpenses />} />
                <Route path='/expenses/update/:id' element={<EditExpense />} />

                <Route path='/users/me' element={<UserEdit />} />

              </Route>
              <Route path='*' element={<Error404 />} />

            </Routes>
          </Box>
        </AuthProvider>

      </BrowserRouter>
    </>
  );
}

export default App;
