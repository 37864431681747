// src/EditClient.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { Add as AddIcon } from '@mui/icons-material';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Table,
    Box,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import Accordion from '../../components/Accordion';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import InvoiceRetentionTable from '../../components/InvoiceRetentionTable';
import CreatePdf from '../../components/CreatePdf';

function EditClient() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [buildings, setBuildings] = useState([]);
    const [certifications, setCertifications] = useState([]);

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);
    const [totalRetention, setTotalRetention] = useState(null);
    const [paidRetention, setPaidRetention] = useState(null);
    const [remainingRetentionSum, setRemainingRetentionSum] = useState(null);

    const [documents, setDocuments] = useState([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [typeDelete, setTypeDelete] = useState(false);

    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [selectedCert, setSelectedCert] = useState(null);
    const [retention, setRetention] = useState(null);
    const [vat, setVat] = useState(null);
    const [retentionDate, setRetentionDate] = useState(null);

    const [clientForm, setClientForm] = useState({
        email: null,
        name: null,
        address: null,
        cif: null,
        country: null,
        cp: null,
        autonomus_community: null,
        province: null,
        telephone_number: null
    });

    const fetchClient = useCallback(async () => {
        try {
            const result = await api().get(`/clients/${id}`);
            const client = result.data.client
            setClientForm(client);
            setClientForm(prevState => ({ ...prevState, client_id: id }));
            setInvoices(client.invoices);
            setCertifications(client.certifications);
            setTotalPriceSum(result.data.total_price_sum);
            setPaidAmountSum(result.data.paid_amount_sum);
            setRemainingAmountSum(result.data.remaining_amount_sum);
            setTotalRetention(result.data.total_retentions);
            setPaidRetention(result.data.paid_retention);
            setRemainingRetentionSum(result.data.remaining_retention_sum);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo cliente");
            setLoading(false);
        }
    }, [api, id]);

    const fetchDocuments = useCallback(async () => {
        try {
            const params = { client_id: id };
            const result = await api().get(`/documents/`, { params });
            setDocuments(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo documentos");
            setLoading(false);
        }
    }, [api, id]);

    const fetchBuildings = useCallback(async () => {
        try {
            const params = { client_id: id };
            const result = await api().get(`/buildings/`, { params });
            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo obras");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchClient();
        fetchDocuments();
        fetchBuildings();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientForm({ ...clientForm, [name]: value });
    };

    const validateForm = () => {
        if (!clientForm.name) {
            return "Por favor, añada un nombre";
        }
        if (!clientForm.email) {
            return "Por favor, añada un email";
        }
        if (clientForm.email.length > 36) {
            return "El email no debe exceder los 36 caracteres";
        }
        if (clientForm.name.length > 36) {
            return "El nombre no debe exceder los 36 caracteres";
        }
        if (clientForm.country && clientForm.country.length > 36) {
            return "El pais no debe exceder los 36 caracteres";
        }
        if (clientForm.cp && clientForm.cp.length > 9) {
            return "El codigo postal no debe exceder los 9 caracteres";
        }
        if (clientForm.autonomus_community && clientForm.autonomus_community.length > 150) {
            return "La comunidad autonoma no debe exceder los 150 caracteres";
        }
        if (clientForm.province && clientForm.province.length > 150) {
            return "La provincia no debe exceder los 150 caracteres";
        }
        if (clientForm.surname && clientForm.surname.length > 36) {
            return "Los apellidos no deben exceder los 36 caracteres";
        }
        if (clientForm.cif && clientForm.cif.length > 9) {
            return "El cif no debe exceder los 9 caracteres";
        }
        if (clientForm.address && clientForm.address.length > 150) {
            return "La dirección no debe exceder los 150 caracteres";
        }
        if (clientForm.telephone_number && clientForm.telephone_number.length > 15) {
            return "El teléfono no debe exceder los 15 caracteres";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }
            setLoading(true);
            const result = await api().patch(`/clients/update`, clientForm);
            if (result.status < 300) {
                alert('Cliente actualizado correctamente');
                fetchClient();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError("Por favor selecciona un archivo");
            return;
        }
        const formData = new FormData();
        formData.append('document', selectedFile);
        formData.append('client_id', id);

        try {
            setLoading(true);
            const result = await api().post(`/documents/add`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (result.status < 300) {
                alert('Documento subido correctamente');
                fetchDocuments();
                setUploadModalOpen(false);
                setSelectedFile(null);
                setUploadError(null);
            } else {
                setUploadError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setUploadError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };
    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString(undefined, options);
    };
    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleDeleteItem = async () => {
        try {
            let data = {}
            if (typeDelete === '/certifications/disable') data.certification_id = deleteId
            if (typeDelete === '/buildings/disable') data.building_id = deleteId
            if (typeDelete === '/invoices/disable') data.invoice_id = deleteId
            if (typeDelete === '/clients/disable') data.client_id = deleteId
            if (typeDelete === '/documents/disable') data.document_id = deleteId

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/clients/disable') {
                    navigate(`/clients/list`);
                } else if (typeDelete === '/buildings/disable') {
                    fetchBuildings();
                } else if (typeDelete === '/documents/disable') {
                    fetchDocuments();
                } else {
                    fetchClient();
                }
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        };
    };

    const handleClickOpenInvoice = (cert) => {
        setSelectedCert(cert);
        setOpenInvoiceModal(true);
    };
    const handleCloseInvoice = () => {
        setRetention(null);
        setRetentionDate(null);
        setOpenInvoiceModal(false);
    };
    const handleChangeRetention = (event) => {
        const { name, value } = event.target;
        setRetention(value)
    };
    const handleChangeVat = (event) => {
        const { name, value } = event.target;
        setVat(value)
    };
    const handleRetentionDateChange = (newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        setRetentionDate(date);
    };
    const handleCreateInvoice = async () => {
        try {
            setLoading(true);
            const result = await api().post(`/invoices/new`, { certification: selectedCert.id, vat: vat, retention: retention, retention_date: retentionDate });
            if (result.status < 300) {
                alert('Factura creada correctamente');
                handleCloseInvoice();
                fetchClient();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: 20 }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {clientForm.code} - {clientForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${clientForm.code} ${clientForm.name}`}>
                            <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={clientForm.name}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.name }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Codigo cliente"
                                            name="code"
                                            value={clientForm.code}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: !!clientForm.code }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="CIF"
                                            name="cif"
                                            value={clientForm.cif}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.cif }}
                                            inputProps={{ maxLength: 9 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={clientForm.email}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.email }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="telephone_number"
                                            value={clientForm.telephone_number}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.telephone_number }}
                                            inputProps={{ maxLength: 15 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="País"
                                            name="country"
                                            value={clientForm.country}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.country }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Comunidad autonoma"
                                            name="autonomus_community"
                                            value={clientForm.autonomus_community}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.autonomus_community }}
                                            inputProps={{ maxLength: 150 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Provincia"
                                            name="province"
                                            value={clientForm.province}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.province }}
                                            inputProps={{ maxLength: 150 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Código postal"
                                            name="cp"
                                            value={clientForm.cp}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.cp }}
                                            inputProps={{ maxLength: 9 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={clientForm.address}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!clientForm.address }}
                                            inputProps={{ maxLength: 150 }}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDeleteDialog(id, '/clients/disable')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                            Actualizar
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title="Documentos">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setUploadModalOpen(true)}
                                startIcon={<AddIcon />}
                                style={{ marginTop: 10 }}
                            >
                                Subir documento
                            </Button>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documents.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Button onClick={() => handleDownloadDocument(item.id, item.name)}>
                                                        {item.name}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDeleteDialog(item.id, '/documents/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
                                <DialogTitle>Subir documento (pdf o word hasta 20mb)</DialogTitle>
                                <DialogContent>
                                    <input type="file" onChange={handleFileChange} />
                                    {uploadError && (
                                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                            {uploadError}
                                        </Typography>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setUploadModalOpen(false)} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button onClick={handleUpload} color="primary" variant='contained'>
                                        Enviar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Accordion>
                        <Accordion title="Obras">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Presupuesto</TableCell>
                                            <TableCell>Estado</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {buildings.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Link to={`/buildings/update/${item.id}`}>
                                                        {item.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell>{item.budget ? item.budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}</TableCell>
                                                <TableCell>
                                                    {item.building_status === 'not_started' ? 'NO EMPEZADA' :
                                                        item.building_status === 'in_course' ? 'EN CURSO' :
                                                            item.building_status === 'finished' ? 'TERMINADA' :
                                                                item.offer_status}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleOpenDeleteDialog(item.id, '/buildings/disable')}
                                                        sx={{ ml: 2 }}
                                                    >
                                                        Borrar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                        <InvoiceRetentionTable
                            isInvoice={true}
                            isClient={true}
                            showBuilding={true}
                            showClientOrProvider={false}
                            title='Facturas'
                            totalAmount={totalPriceSum}
                            paidAmount={paidAmountSum}
                            remainingAmountSum={remainingAmountSum}
                            invoices={invoices}
                            fetch={fetchClient}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                        />
                        <InvoiceRetentionTable
                            isInvoice={false}
                            isClient={true}
                            showBuilding={true}
                            showClientOrProvider={false}
                            title='Retenciones'
                            totalAmount={totalRetention}
                            paidAmount={paidRetention}
                            remainingAmountSum={remainingRetentionSum}
                            invoices={invoices}
                            fetch={fetchClient}
                            handleOpenDeleteDialog={handleOpenDeleteDialog}
                        />
                        <Accordion title='Certificaciones'>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Facturada</TableCell>
                                            <TableCell>Precio</TableCell>
                                            <TableCell>Fecha</TableCell>
                                            <TableCell>Obra</TableCell>
                                            <TableCell>Partes de la obra</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {certifications.filter(item => !item.disabled).map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.code}</TableCell>
                                                <TableCell>{item.invoiced ? "Sí" : "No"}</TableCell>
                                                <TableCell>{item.price ? item.price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}</TableCell>
                                                <TableCell>{formatDate(item.certification_date)}</TableCell>
                                                <TableCell>
                                                    <Link to={`/buildings/update/${item.building.id}`}>
                                                        {item.building.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {item.item_progress.map((product, idx) => (
                                                        <div key={idx}>{product.item.name} - {product.percentage}%</div>
                                                    ))}
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained" sx ={{ marginRight: '10px' }} color="primary" disabled={item.invoiced} onClick={() => handleClickOpenInvoice(item)}>
                                                        Crear factura
                                                    </Button>
                                                    <CreatePdf
                                                        endpoint={`/certifications/${item.id}/generate-pdf`}>
                                                    </CreatePdf>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleOpenDeleteDialog(item.id, '/certifications/disable')}
                                                    >
                                                        Borrar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {selectedCert && (
                            <Dialog open={openInvoiceModal} onClose={handleCloseInvoice}>
                                <DialogTitle>Crear Factura</DialogTitle>
                                <DialogContent>
                                    <Grid>
                                        <TextField
                                            name="vat"
                                            label="Iva"
                                            fullWidth
                                            value={vat}
                                            onChange={handleChangeVat}
                                            margin="normal"
                                            type="number"
                                            inputProps={{
                                                min: "0",
                                                max: "100",
                                                step: "1"
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <TextField
                                            name="retention"
                                            label="Retención (porcentaje)"
                                            fullWidth
                                            value={retention}
                                            onChange={handleChangeRetention}
                                            margin="normal"
                                            type="number"
                                            inputProps={{
                                                min: "0",
                                                max: "100",
                                                step: "1"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="es"
                                            localeText={{
                                                cancelButtonLabel: "cancelar",
                                                okButtonLabel: "Ok",
                                                datePickerToolbarTitle: 'Seleccionar',
                                            }}>
                                            <MobileDatePicker
                                                label="Fecha maxima retencion"
                                                closeOnSelect={true}
                                                onChange={(newValue) => handleRetentionDateChange(newValue)}
                                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <br />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseInvoice} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button color="primary" disabled={!retention || !retentionDate} onClick={handleCreateInvoice}>
                                        Crear Factura
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        )}

                        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                            <DialogTitle>¿Estás seguro?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="primary">
                                    Volver
                                </Button>
                                <Button onClick={handleDeleteItem} color="error">
                                    Eliminar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default EditClient;
