import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Box,
    Autocomplete,
    Paper,
    Typography,
    CircularProgress
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function NewCertification() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const building = location.state ? location.state.building : null;

    const [buildings, setBuildings] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const [certificationForm, setCertificationForm] = useState({
        description: null,
        certification_date: null,
        building_id: null,
        price: null,
    });

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchBuildings();
        if (building){
            setBuildingSelected(building);
            setCertificationForm(prevState => ({
                ...prevState,
                building_id: building.building_id
            }));
        }
    }, [building]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCertificationForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleDateChange = (newValue) => {
        let newDate = dayjs(newValue).hour(12).minute(0).second(0);
        newDate = newDate.utc().format('YYYY-MM-DD');
        setCertificationForm(prevState => ({
            ...prevState,
            certification_date: newDate
        }));
    };
    const handleBuildingChange = (event, newValue) => {
        setBuildingSelected(newValue);
        setCertificationForm(prevState => ({
            ...prevState,
            building_id: newValue.id
        }));
    };
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const validateForm = () => {
        if (!certificationForm.description) {
            return "Por favor, añada una descripción";
        }
        if (!certificationForm.certification_date) {
            return "Por favor, añada una fecha";
        }
        if (!certificationForm.building_id) {
            return "Por favor, añada una obra";
        }
        if (certificationForm.price) {
            const priceRegex = /^\d+(\.\d{1,2})?$/;
            if (!priceRegex.test(certificationForm.price.toString())) {
                return "El precio debe ser un número con máximo dos decimales";
            }
            if (parseFloat(certificationForm.price) > 99000000) {
                return "El presupuesto no puede ser mayor a 99 millones";
            }
        }
        return null;
    };

    const createCertification = async (event) => {
        event.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            const formData = new FormData();
            formData.append('description', certificationForm.description);
            formData.append('certification_date', certificationForm.certification_date);
            formData.append('building_id', certificationForm.building_id);
            formData.append('price', certificationForm.price);
            if (selectedFile) {
                formData.append('document', selectedFile);
            }

            setLoading(true);
            const result = await api().post('/certifications/new', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Certificación creada correctamente");
                    navigate(`/certifications/list`);
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nueva Certificación
                </Typography>
                <br></br>
                <form onSubmit={createCertification}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Descripción"
                                name="description"
                                value={certificationForm.description}
                                onChange={handleChange}
                                margin="normal"
                                inputProps={{ maxLength: 255 }}
                            />
                            <TextField
                                fullWidth
                                label="Precio"
                                name="price"
                                value={certificationForm.price}
                                onChange={handleChange}
                                margin="normal"
                                type="number"
                            />
                            <Autocomplete
                                options={buildings}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={buildingSelected}
                                onChange={handleBuildingChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        label="Obras"
                                        style={{ maxWidth: '100%' }}
                                    />
                                )}
                            />
                            <br />
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                                localeText={{
                                    cancelButtonLabel: "cancelar",
                                    okButtonLabel: "Ok",
                                    datePickerToolbarTitle: 'Seleccionar',
                                }}>
                                <MobileDatePicker
                                    label="Fecha"
                                    closeOnSelect={true}
                                    value={certificationForm.certification_date ? dayjs(certificationForm.certification_date) : null}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <br />
                            <input
                                accept="application/pdf"
                                id="contained-button-file"
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="contained-button-file" style={{ display: 'block', margin: '20px 0' }}>
                                <input
                                    accept="application/pdf"
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                <Button variant="contained" component="span">
                                    {selectedFile ? 'Cambiar documento' : 'Añadir documento'}
                                </Button>
                                <Typography variant="body2" style={{ marginTop: '10px' }}>
                                    Solo se admiten pdf o word
                                </Typography>
                                {selectedFile && (
                                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                                        {selectedFile.name}
                                    </Typography>
                                )}
                            </label>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}
            </Paper >
        </div>
    );
}

export default NewCertification;
