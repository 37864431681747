import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { isBefore, subYears } from 'date-fns';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BasicDatePicker from "../../components/BasicDatePicker";
import { useAuth } from './OAuth.js'
import logimsaImage from '../../images/logimsa.png';


const defaultTheme = createTheme();
const apiUrl = process.env.REACT_APP_API_URL;

export default function SignUp() {

  const navigate = useNavigate();
  const { login } = useAuth();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const [registerForm, setRegisterform] = useState({
    username: "",
    name: "",
    surname: "",
    email: "",
    country: "",
    born_date: null,
    password: "",
    rol: "User",
  });

  const onChangeForm = (label, event) => {
    switch (label) {
      case "username":
        setRegisterform({ ...registerForm, username: event.target.value });
        break;
      case "name":
        setRegisterform({ ...registerForm, name: event.target.value });
        break;
      case "surname":
        setRegisterform({ ...registerForm, surname: event.target.value });
        break;
      case "email":
        setRegisterform({ ...registerForm, email: event.target.value });
        break;
      case "country":
        setRegisterform({ ...registerForm, country: event.target.value });
        break;
      case "born_date":
        setRegisterform({ ...registerForm, born_date: event.format('YYYY-MM-DD') });
        break;
      case "password":
        setRegisterform({ ...registerForm, password: event.target.value });
        break;
      default:
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const todayMinus16Years = subYears(new Date(), 16);

      if (registerForm.password !== confirmPassword) {
        setError("Las contraseñas no coinciden")
        return;
      }

      if (!emailRegex.test(registerForm.email)) {
        setError("Email inválido")
      } else if (isBefore(todayMinus16Years, new Date(registerForm.born_date))) {
        setError("Fecha inválida")
      } else {

        await axios.post(apiUrl+"/users/sign", registerForm);

        const data = new URLSearchParams();
        data.append("username", registerForm.username);
        data.append("password", registerForm.password);
        axios.post("http://localhost:8000/login", data)
          .then(response => {
            if (response.data.access_token) {
              login(response.data.access_token)
              navigate('/');
            }
            else {
              setError("Inicio de sesión fallido")
            }
          })
      }
    } catch (error) {
      setError(error)
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logimsaImage} alt="Logo" />

          <Typography component="h1" variant="h5">
            Registro
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="username"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  autoFocus
                  value={registerForm.username}
                  onChange={(event) => {
                    onChangeForm("username", event);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nombre"
                  value={registerForm.name}
                  onChange={(event) => {
                    onChangeForm("name", event);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="surname"
                  required
                  fullWidth
                  id="surname"
                  label="Apellidos"
                  value={registerForm.surname}
                  onChange={(event) => {
                    onChangeForm("surname", event);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={registerForm.email}
                  onChange={(event) => {
                    onChangeForm("email", event);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <BasicDatePicker
                  required
                  fullWidth
                  id="born_date"
                  label="Fecha de nacimiento"
                  name="born_date"
                  value={registerForm.born_date}
                  onChange={(event) => {
                    onChangeForm("born_date", event);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  value={registerForm.password}
                  onChange={(event) => {
                    onChangeForm("password", event);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirma la contraseña"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Button
              toLink="/"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: '#E66136', color: 'white', '&:hover': {backgroundColor: '#913d21'}}}
              disabled={!registerForm.username || !registerForm.name
                || !registerForm.surname || !registerForm.email
                || !registerForm.password}
            >
              Regístrate
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={`/sign-in`} variant="body2">
                  ¿Ya tienes cuenta? Inicia sesión
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {error && (
          <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
            {error}
          </Typography>
        )}
      </Container>
    </ThemeProvider>
  );
}