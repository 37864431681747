import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Autocomplete,
    Dialog,
    Grid,
    DialogTitle,
    CircularProgress,
    DialogActions,
    Typography
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);


function ListCertifications() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(null);

    const [certifications, setCertifications] = useState([]);

    const [buildings, setBuildings] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchCertifications = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage
            if (buildingSelected) {
                params.building_id = buildingSelected.id;
            }
            if (startDate) {
                const startDateMidday = dayjs(startDate).hour(12).minute(0).second(0);
                params.start_date = startDateMidday.utc().format('YYYY-MM-DD');
            }
            if (endDate) {
                const endDateMidday = dayjs(endDate).hour(12).minute(0).second(0);
                params.end_date = endDateMidday.utc().format('YYYY-MM-DD');
            }
            const result = await api().get("/certifications/", { params });

            setCertifications(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, itemsPerPage, buildingSelected, startDate, endDate])

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchCertifications();
        fetchBuildings();
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        if (fetchReset) {
            fetchCertifications();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleDeleteItem = async () => {
        try {
            const result = await api().delete(`/certifications/delete`, {
                data: { certification_id: deleteItemId }
            });
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchCertifications();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleApplyFilters = () => {
        fetchCertifications();
    };

    const handleBuildingChange = (event, newValue) => {
        setBuildingSelected(newValue);
    };
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
    };
    const handleResetFilters = () => {
        setBuildingSelected(null);
        setStartDate(null);
        setEndDate(null);
        setFetchReset(true);
    };
    const formatDate = (date) => {
        if (!date) {
            return '';
        }
        return dayjs(date).format('DD/MM/YYYY');
    };
    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };
    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={buildingSelected}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obras"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha de Inicio"
                                            closeOnSelect={true}
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha de Fin"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            closeOnSelect={true}
                                            renderInput={(params) => <TextField {...params} />}

                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Obra</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Fecha</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Descripción</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Precio</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Retención</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>Acciones</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {certifications.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.building.name}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatDate(item.certification_date)}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.description}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.price ? item.price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            {item.retention}€
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Link to={`/certifications/update/${item.id}`}>
                                                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                    <FaInfoCircle />
                                                </Button>
                                            </Link>
                                            <Button onClick={() => handleOpenDeleteDialog(item.id)} variant="contained" color="error">
                                                <FaTrash />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>¿Estás seguro de que quieres borrar esta certificación?</DialogTitle>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">
                                            Volver
                                        </Button>
                                        <Button onClick={handleDeleteItem} color="error">
                                            Eliminar
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={setCurrentPage}
            />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    )
}
export default ListCertifications;