import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { Add as AddIcon } from '@mui/icons-material';
import {
    TextField,
    Button,
    Typography,
    Grid,
    Table,
    Autocomplete,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    CircularProgress
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function EditExpense() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    const [documents, setDocuments] = useState([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState(null);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const [expenseForm, setExpenseForm] = useState({
        title: null,
        expense_date: null,
        building_id: null,
        price: null,
        description: null,
    });

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);
    const fetchExpense = useCallback(async () => {
        try {
            const result = await api().get(`/expenses/${id}`);
            setExpenseForm(result.data);
            setBuildingSelected(result.data.building);
            setExpenseForm(prevState => ({ ...prevState, expense_id: id }));
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo gasto");
            setLoading(false);
        }
    }, [api, id]);

    const fetchDocuments = useCallback(async () => {
        try {
            const params = { expense_id: id };
            const result = await api().get(`/documents/`, { params });
            setDocuments(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo documentos");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchExpense();
        fetchDocuments();
        fetchBuildings();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setExpenseForm({ ...expenseForm, [name]: value });
    };

    const validateForm = () => {
        if (!expenseForm.description) {
            return "Por favor, añada una descripción";
        }
        if (!expenseForm.expense_date) {
            return "Por favor, añada una fecha";
        }
        if (!expenseForm.title) {
            return "Por favor, añada un título";
        }
        if (!expenseForm.price) {
            return "Por favor, añada un precio";
        }
        if (expenseForm.title && expenseForm.title.lenght > 36) {
            return "El titulo no puede superar los 36 caracteres";
        }
        const priceRegex = /^\d+(\.\d{1,2})?$/;
        if (!priceRegex.test(expenseForm.price.toString())) {
            return "El precio debe ser un número con máximo dos decimales";
        }
        if (parseFloat(expenseForm.price) > 99000000) {
            return "El precio no puede ser mayor a 99 millones";
        }
        return null;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }
            setLoading(true);
            const result = await api().patch(`/expenses/update`, expenseForm);
            if (result.status < 300) {
                alert('Gasto actualizado correctamente');
                fetchExpense()
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };
    const handleDeleteDocument = (documentId) => {
        setDocumentToDelete(documentId);
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            setLoading(true);
            const result = await api().delete(`/documents/delete`, {
                data: { document_id: documentToDelete }
            });
            if (result.status < 300) {
                alert('Documento eliminado correctamente');
                fetchDocuments();
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
            setDeleteDialogOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setDeleteDialogOpen(false);
    };


    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const handleDateChange = (newValue) => {
        let date = dayjs(newValue).hour(12).minute(0).second(0);
        date = date.utc().format('YYYY-MM-DD');
        setExpenseForm(prevState => ({
            ...prevState,
            expense_date: date
        }));
    };
    const handleBuildingChange = (event, newValue) => {
        setBuildingSelected(newValue);
        setExpenseForm(prevState => ({
            ...prevState,
            building_id: newValue.id
        }));
    };
    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError("Por favor selecciona un archivo");
            return;
        }
        const formData = new FormData();
        formData.append('document', selectedFile);
        formData.append('expense_id', id);

        try {
            setLoading(true);
            const result = await api().post(`/documents/add`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (result.status < 300) {
                alert('Documento subido correctamente');
                fetchDocuments();
                setUploadModalOpen(false);
                setSelectedFile(null);
                setUploadError(null);
            } else {
                setUploadError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setUploadError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    return (
        <Grid container justifyContent="center">
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid item xs={12} sm={8} md={6} lg={4}>

                        <Typography variant="h5" gutterBottom>
                            Actualizar gasto
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Título"
                                        name="title"
                                        value={expenseForm.title}
                                        onChange={handleChange}
                                        margin="normal"
                                        inputProps={{ maxLength: 36 }}
                                        InputLabelProps={{ shrink: !!expenseForm.title }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Precio"
                                        name="price"
                                        value={expenseForm.price}
                                        onChange={handleChange}
                                        margin="normal"
                                        type="number"
                                        InputLabelProps={{ shrink: !!expenseForm.price }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Descripción"
                                        name="description"
                                        value={expenseForm.description}
                                        onChange={handleChange}
                                        margin="normal"
                                        inputProps={{ maxLength: 255 }}
                                        InputLabelProps={{ shrink: !!expenseForm.description }}
                                    />
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={buildingSelected}
                                        disabled
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obras"
                                                margin="normal"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                    <br />
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha"
                                            closeOnSelect={true}
                                            value={expenseForm.expense_date ? dayjs(expenseForm.expense_date) : null}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <br />
                            <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                Actualizar
                            </Button>
                        </form>
                        {error && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item style={{ marginLeft: '70px' }}>
                        <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
                            Documentos
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setUploadModalOpen(true)}
                            startIcon={<AddIcon />}
                            style={{ marginTop: 10 }}
                        >
                            Subir documento
                        </Button>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documents.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Button onClick={() => handleDownloadDocument(item.id, item.name)}>
                                                    {item.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => handleDeleteDocument(item.id)}
                                                >
                                                    Eliminar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Dialog
                            open={deleteDialogOpen}
                            onClose={handleCancelDelete}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Confirmar eliminación</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    ¿Estás seguro de que quieres eliminar este documento?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCancelDelete} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleConfirmDelete} color="secondary" autoFocus>
                                    Eliminar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
                            <DialogTitle>Subir documento (pdf o word hasta 20mb)</DialogTitle>
                            <DialogContent>
                                <input type="file" onChange={handleFileChange} />
                                {uploadError && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {uploadError}
                                    </Typography>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setUploadModalOpen(false)} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleUpload} color="primary" variant='contained'>
                                    Enviar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </>
            )}
        </Grid>

    );
}

export default EditExpense;
